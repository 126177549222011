import type { Moment, Duration } from "moment";
import {
  Allow,
  IsArray,
  IsBoolean,
  IsEnum,
  IsInt,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from "class-validator";
import { Transform, Type } from "class-transformer";
import { transformDuration, transformMoment } from "../helpers/transforms";
import { IsDuration, IsMoment } from "../helpers/decorators";
import { User } from "./user";

export class QuizLogSession {
  @Allow()
  user: User;

  @IsUUID()
  quizSessionId: string;
  @IsUUID()
  quizSelectionId: string;
  @IsUUID()
  clientId: string;
  @IsOptional()
  @IsString()
  linkId?: string;
  @IsString()
  categoryId: string;
  @IsNumber()
  score: number;
  @IsNumber()
  scoreAdj: number;
  @Transform(transformDuration)
  @IsDuration()
  time: Duration;
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
}

export class QuizLogEntry {
  @Allow()
  user: User;

  @IsUUID()
  quizLogId: string;
  @IsString()
  quizSessionId: string;
  @IsUUID()
  quid: string;
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsString()
  area: string;
  @IsInt()
  questionNumber: number;
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => QuizLogEntryOption)
  options: Array<QuizLogEntryOption>;
  @IsNumber()
  score: number;
  @IsNumber()
  scoreAdj: number;
  @Transform(transformDuration)
  @IsDuration()
  time: Duration;
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  // TODO to be removed
  @IsOptional()
  @IsBoolean()
  rectified: boolean;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
}

export class QuizLogEntryOption {
  @IsEnum(["a", "b", "c", "d", "e", "f", "g", "h"])
  option: string;
  @IsInt()
  toggles: number;
  @IsBoolean()
  checked: boolean;
  @IsBoolean()
  correct: boolean;
}
