import type { Moment, Duration } from "moment";
import { Allow, IsEnum, IsNumber, IsOptional, IsString, IsUUID } from "class-validator";
import { Transform } from "class-transformer";
import { transformDuration, transformMoment } from "../helpers/transforms";
import { IsDuration, IsMoment } from "../helpers/decorators";
import { User } from "./user";

export const studyMaterialLogAccesses = ["view", "download"] as const;
export type StudyMaterialLogAccess = (typeof studyMaterialLogAccesses)[number];

export class StudyMaterialLogSession {
  @Allow()
  user: User;

  @IsUUID()
  studyMaterialSessionId: string;
  @IsString()
  studyGroupId: string;
  @IsString()
  studyMaterialId: string;
  @IsUUID()
  clientId: string;
  @IsEnum(studyMaterialLogAccesses)
  access: StudyMaterialLogAccess;
  @Transform(transformDuration)
  @IsDuration()
  time: Duration;
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
}

export class StudyMaterialLogEntry {
  @Allow()
  user: User;

  @IsUUID()
  studyMaterialLogId: string;
  @IsString()
  studyMaterialSessionId: string;
  @IsOptional()
  @IsNumber()
  page?: number;
  @Transform(transformDuration)
  @IsDuration()
  time: Duration;
  @IsEnum(studyMaterialLogAccesses)
  access: StudyMaterialLogAccess;
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
}
