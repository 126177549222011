import { Transform, Type } from "class-transformer";
import { IsArray, IsInt, IsNumber, IsOptional, IsString, ValidateIf, ValidateNested } from "class-validator";
import type { Duration, Moment } from "moment";

import { User } from "../user";
import { QuizLogEntryOption } from "../quizLogEntry";
import { transformDuration, transformMoment } from "../../helpers/transforms";
import { IsDuration, IsMoment } from "../../helpers/decorators";

export class QuizResultQuestion {
  @IsString()
  topicId: string;
  @IsString()
  quid: string;
  @IsInt()
  questionNumber: number;
}

export class QuizResultUserAnswers {
  @Type(() => User)
  @ValidateNested()
  user: User;
  @IsOptional()
  @IsNumber()
  score: number;
  @IsOptional()
  @IsNumber()
  scoreAdj: number;
  @IsOptional()
  @Transform(transformDuration)
  @IsDuration()
  time: Duration;
  @IsOptional()
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  @IsArray()
  @Type(() => QuizResultUserAnswerAnswer)
  @ValidateNested({ each: true })
  @ValidateIf(
    (_obj, value) =>
      // TODO this is a hack to make sure that validation passes if the array contains nulls
      Array.isArray(value) && value.every((a) => !!a),
  )
  answers: (QuizResultUserAnswerAnswer | null)[];
}

export class QuizResultUserAnswerAnswer {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => QuizLogEntryOption)
  options: Array<QuizLogEntryOption>;
  @IsNumber()
  score: number;
  @IsNumber()
  scoreAdj: number;
  @Transform(transformDuration)
  @IsDuration()
  time: Duration;
}

export class QuizResults {
  @IsString()
  categoryId: string;
  @IsArray()
  @Type(() => QuizResultQuestion)
  @ValidateNested({ each: true })
  questions: QuizResultQuestion[];
  @IsArray()
  @Type(() => QuizResultUserAnswers)
  @ValidateNested({ each: true })
  userAnswers: QuizResultUserAnswers[];
}
